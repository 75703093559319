import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { FixedFooter, NextBtn } from '../../common.style';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";

const TEXT = {
  submitEmail: '填寫已註冊電郵',
  email: '電郵',
  nextStep: '下一步',
}

function Content(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: undefined,
      email: "",
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      email: Yup.string().email().required().label(TEXT.email),
    }),
    onSubmit: async (values, helpers) => {
      await handleSubmit(values, helpers);
    },
  });

  useEffect(() => {
    
  }, []);

  const handleSubmit = (values, helpers) => {
    setLoading(true);
    
    setLoading(false);

    navigate('/forget_password/validate_email');
  }

  return (
    <>
    <div style={{padding: '20px'}}>
      <Typography variant='body1' style={{fontSize: '18px', marginBottom: '10px'}}>{TEXT.submitEmail}</Typography>
      <TextField
        variant="outlined"
        fullWidth
        label={TEXT.email}
        name="email"
        type="email"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.email}
        helperText={
          Boolean(formik.touched.email && formik.errors.email) &&
          formik.errors.email
        }
        error={Boolean(formik.touched.email && formik.errors.email)}
      />
    </div>
    <div style={{marginTop: '9vh'}}></div>
    <FixedFooter style={{height: '10vh'}}>
        <NextBtn onClick={() => formik.handleSubmit()} disabled={loading}>{TEXT.nextStep}</NextBtn>
    </FixedFooter>
    </>
  )
}

export default Content;