import { useEffect, useState, useRef, createRef } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Link } from "react-router-dom";
import { getEventPage, getEventPhoto } from '../../../../api/apis';
import { convertApiDateTimeToDisplayDateTime } from '../../../../util/date'
import { convertBase64 } from '../../../../util/fileUpload'
import { height } from '@mui/system';

const TEXT = {

}

const EventBox = styled(Box)(({ theme }) => ({
    border: '1px solid #FFFFFF',
    color: 'white',
    margin: '0px 0px 30px 0px',
    display: 'flex',
}));

const EventBoxDesc = styled(Typography)(({ theme }) => ({
    fontSize: '12px',
    paddingLeft: '5px',
}));

function Content(props) {
  const [data, setData] = useState([]);
  const [imgMap, setImgMap] = useState({});
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const lastChildOfTheList = useRef();


  // unused function for now
  const handleScroll = async () => {
    if(lastChildOfTheList.current){
      const lastChildPosition = lastChildOfTheList.current.getBoundingClientRect().y + lastChildOfTheList.current.getBoundingClientRect().height + 50;
      const screenHeight = window.screen.height;
      const buffer = 50;
      if( lastChildPosition - buffer < screenHeight && lastChildPosition + buffer > screenHeight){
        if(!loading){
          console.log('time to append list');
        //   let currentPage = page + 1;
        //   console.log(currentPage);
        //   setPage(currentPage);
        //   setLoading(true);
        //   await getEventByPage(currentPage);
        //   setLoading(false);
        }
      }
    }
  };
  

  useEffect(() => {
    getEventByPage(0);

    // window.addEventListener('scroll', handleScroll, { passive: true });
  }, []);

  async function getEventByPage(currentPage){
    const params = {
      page: currentPage,
      size: 100
    }
    setLoading(true);
    getEventPage({ params }).then(res => {
      res.data.content.map(row => {
        
        getPhotoById(row.id).then(img => {
          if(img !== ''){
            setImgMap(prevState => ({
              ...prevState,
              [row.id]: img
            }));
          } else {
            
          }
        });
      })
      setData(res.data.content);
      setLoading(false);
    });
  }

  async function getPhotoById(id){
    return getEventPhoto({ id }).then(res => {
      if(res.status == 200){
        return convertBase64(res.data).then(img => {
          return `data:image/png;base64,${img}`;
        });
      } else {
        return '';
      }
    }).catch(err => {
      return '';
    })
  }

  return (
    <div onScroll={() => handleScroll()} style={{padding: '20px', height: '80%', overflow: 'scroll'}}>
      {data.map(row => (
        <Link key={row.id} to={`/event/detail/${row.id}`} style={{textDecoration: 'none'}} ref={lastChildOfTheList}>
          <EventBox key={row.id}>
            <Grid container spacing={2} style={{margin:0, padding: '10px', display: 'flex'}} justifyContent='center' alignItems='center'>

              <Grid item style={{padding:0, display: 'flex', width: '40%', height: '70px'}} justifyContent='center' alignItems='center'>
                <img src={imgMap[row.id]?imgMap[row.id]:''} style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}}/>
              </Grid>
              
              <Grid container spacing={2} style={{paddingLeft:10, width: '60%'}} alignItems='center'>

                <Grid item xs={12} style={{display: 'flex'}} alignItems='center'>
                  <Typography variant='h7'>{row.title}</Typography>
                </Grid>

                <Grid item xs={12} style={{display: 'flex'}} alignItems='center'>
                  <img src='/images/ic_event_on.png' width={10}/>
                  <EventBoxDesc variant=''>{convertApiDateTimeToDisplayDateTime(row.eventStartDatetime)}</EventBoxDesc>
                </Grid>

                <Grid item xs={12} style={{display: 'flex'}} alignItems='center'>
                  <img src='/images/ic_event_location.png' width={10}/>
                  <EventBoxDesc variant=''>{row.location}</EventBoxDesc>
                </Grid>
            
              </Grid>
            </Grid>
          </EventBox>
        </Link>
      ))}
    </div>
  )
}

export default Content;