import { useEffect, createContext, useState, useContext } from 'react';
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { login as LoginApi, getMe } from '../api/apis';
import { setAccessToken, setRefreshToken, getAccessToken, clearToken } from './TokenStorage';

export const AuthContext = createContext({
  user: 1,
  setUser: () => {},
  login: () => {},
  logout: () => {},
});

export const AuthProvider = (props) => {
  const location = useLocation();
  const { children } = props;
  const [user, setUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);

  // This function is checking the user is logged in
  useEffect(() => {
    function success(user) {
      setUser(user);
      setWaitAuthCheck(false);
      setIsAuthenticated(true);
    }

    function pass() {
      console.log('passed');
      setWaitAuthCheck(false);
      setIsAuthenticated(false);
    }

    const handleAuthentication = async () => {
      const accessToken = getAccessToken();
      if (!accessToken) {
        pass();
        return;
      }

      getMe().then(res => {
        success(user);
      }).catch(err => {
        pass();
      });
    };

    handleAuthentication();
  }, []);


  const login = (data) => {
    return new Promise((resolve, reject) => {
      LoginApi({ data }).then(res => {
        setAccessToken(res.data.accessToken);
        setRefreshToken(res.data.refreshToken);
        
        getMe().then(userRes => {
          setUser(userRes.data);
          setWaitAuthCheck(false);
          setIsAuthenticated(true);

          resolve(userRes.data);
        })
        
        
      }).catch(err => {
        reject(new Error(err));
      });
    });
  }

  const logout = () => {
    clearToken();
    setWaitAuthCheck(false);
    setIsAuthenticated(false);
    // logoutUser();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
      }}
    >
      {!isAuthenticated && location.pathname !== '/login' && !location.pathname.includes('/forget_password')?
        <Navigate replace to="/login" />
        :isAuthenticated && location.pathname == '/login'? 
        <Navigate replace to="/event/list" />
        :<Outlet/>
      }
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
export const useAuth = () => useContext(AuthContext);