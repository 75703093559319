import axios from "axios";
import { create } from "apisauce";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {
  getAccessToken,
  setAccessToken,
  setRefreshToken,
  clearToken,
} from "../auth/TokenStorage";
import refreshToken from "./refresh-token";
import { useNavigate } from "react-router-dom";

const instance = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 600000, // 600 sec / 10 mins
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// export default instance;
// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) => {
  console.log(failedRequest);
  //
  // if(failedRequest.response.status == 401){
  refreshToken()
    .then((tokenRefreshResponse) => {
      const newAccessToken = tokenRefreshResponse.data.accessToken;
      const newRefreshToken = tokenRefreshResponse.data.refreshToken;
      setAccessToken(newAccessToken);
      setRefreshToken(newRefreshToken);
      failedRequest.response.config.headers.Authorization = `Bearer ${newAccessToken}`;

      console.log("refresh token response: ", tokenRefreshResponse);

      return Promise.resolve();
    })
    .catch((reason) => {
      console.log("catch refresh token error", reason);

      // logout user:
      clearToken();

      // window.location.reload();
      const navigate = useNavigate();
      navigate("/login");
    });
  // } else {

  // }
};

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(instance, refreshAuthLogic);

const apisauceInstance = create({
  axiosInstance: instance,
});

export default apisauceInstance;
