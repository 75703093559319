const maxSize = 1048576; // 1 mb maximum file size 

export const checkSize = (file) => {
  return file.size <= maxSize;
}

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(filterBase64(fileReader.result));
    }
    fileReader.onerror = (error) => {
      reject(error);
    }
  })
}

export const convertFileToText = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsText(file)
    fileReader.onload = () => {
      resolve(fileReader.result);
    }
    fileReader.onerror = (error) => {
      reject(error);
    }
  })
}

const filterBase64 = (data) => {
  const regex = /(data:image\/(png)|data:audio\/(mpeg)|data:application\/(json));base64,/i;
  return data.replace(regex, '');
}