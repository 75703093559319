import { createContext, useState, useContext } from 'react';

export const CheckInEventContext = createContext({
  isCheckingPassShow: false,
  setIsCheckingPassShow: () => {},
  pageJumpTrigger: false,
  setPageJumpTrigger: () => {},
  errorMessage: null,
  setErrorMessage: () => {},
});

export const CheckInEventProvider = (props) => {
  const [isCheckingPassShow, setIsCheckingPassShow] = useState(false);
  const [pageJumpTrigger, setPageJumpTrigger] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(null);

  const { children } = props;
  

  return (
    <CheckInEventContext.Provider
      value={{
        isCheckingPassShow, setIsCheckingPassShow,
        pageJumpTrigger, setPageJumpTrigger,
        errorMessage, setErrorMessage
      }}
    >
      {children}
    </CheckInEventContext.Provider>
  );
};


export const CheckInEventConsumer = CheckInEventContext.Consumer;
export const useCheckInEvent = () => useContext(CheckInEventContext);