import { useEffect } from 'react';
import Content from "./components/Content"
import { useApp } from '../../../App.context';

const TEXT = {
  title: '驗證帳戶',
}

function ValidateEmail(){
  const { setTitle, setShowBackBtn, setShowLogoutBtn } = useApp();

  useEffect(() => {
    setTitle(`${TEXT.title}`);
    setShowBackBtn(true);
    setShowLogoutBtn(false);
  }, []);
  return (
    <>
      <Content/>
    </>
  )
}

export default ValidateEmail;