import { useEffect, useState, useRef, createRef } from 'react';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { convertApiDateTimeToDisplayDateTime } from '../../../../util/date';
import { getEvent, getEventPhoto } from '../../../../api/apis';
import { GET_EVENT_PHOTO } from '../../../../api/constants';
import { useParams } from "react-router-dom";
import { useEventDetail } from './EventDetail.context';
import { convertBase64 } from '../../../../util/fileUpload'

const TEXT = {
  title: '活動',
  eventStartDate: '活動開始日期',
  eventEndDate: '活動結束日期',
  participant: '報名人數',
  people: '人',
  registerDeadline: '報名截止日期',
  eventDesc: '活動簡介',
  eventKnow: '活動須知',
}

const CommentBox = styled(Box)(({ theme }) => ({
  margin: '10px 0px 0px 0px',
  border: '1px solid #FFFFFF',
  borderRadius: '10px',
  color: 'white',
  display: 'flex',
}));

const LikeBox = styled('div')(({ theme }) => ({
  margin: '10px 0px 0px 0px',
  padding: '3px 7px',
  width: '50px',

  border: '1px solid #20ace6',
  borderRadius: '20px',

  backgroundColor: '#20ace6',
  color: 'white',

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
}));

function Content(props) {
  const {data, setData} = useEventDetail();

  const [img, setImg] = useState();
  let { id } = useParams();

  useEffect(() => {
    getEvent({ id }).then(res => {
      console.log(res.data);
      setData(res.data);
    })
    getPhotoById(id).then(img => {
      setImg(img);
    })
  }, []);

  async function getPhotoById(id){
    return getEventPhoto({ id }).then(res => {
      if(res.status == 200){
        return convertBase64(res.data).then(img => {
          return `data:image/png;base64,${img}`;
        });
      } else {
        return '';
      }
    }).catch(err => {
      return '';
    })
  }

  return (
    <div style={{ height: '80%', overflow: 'scroll' }}>
      <img src={img?img:''} style={{width: '100%', maxHeight: '30vh', objectFit: 'cover', objectPosition: 'bottom'}}/>

      <div style={{padding: '15px', display: 'flex', flexDirection: 'column'}}>
        <Typography variant='h5'>{data.title}</Typography>
        <div style={{margin: '20px 0px 0px 2px', display: 'flex', alignItems: 'center'}}>
          <img src='/images/ic_event_on.png' width={15}/><Typography variant='body1' style={{marginLeft: '20px'}}>{TEXT.eventStartDate}: {convertApiDateTimeToDisplayDateTime(data.eventStartDatetime)}</Typography>
        </div>
        <div style={{margin: '5px 0px 0px 2px', display: 'flex', alignItems: 'center'}}>
          <img src='/images/ic_event_on.png' width={15}/><Typography variant='body1' style={{marginLeft: '20px'}}>{TEXT.eventEndDate}: {convertApiDateTimeToDisplayDateTime(data.eventEndDatetime)}</Typography>
        </div>
        <div style={{margin: '5px 0px 0px 2px', display: 'flex', alignItems: 'center'}}>
          <img src='/images/ic_event_location.png' width={15}/><Typography variant='body1' style={{marginLeft: '20px'}}>{data.location}</Typography>
        </div>
        <div style={{margin: '5px 0px 0px 2px', display: 'flex', alignItems: 'center'}}>
          <img src='/images/ic_event_guest.png' width={15}/><Typography variant='body1' style={{marginLeft: '20px'}}>{TEXT.participant}: {data.statistic?`${data.statistic.reservedCount} / ${data.quota}${TEXT.people}`:<></>}</Typography>
        </div>
        <div style={{margin: '5px 0px 0px 2px', display: 'flex', alignItems: 'center'}}>
          <img src='/images/ic_event_time.png' width={15}/><Typography variant='body1' style={{marginLeft: '20px'}}>{TEXT.registerDeadline}: {convertApiDateTimeToDisplayDateTime(data.registerEndDatetime)}</Typography>
        </div>
      </div>

      <Divider style={{margin: '5px 15px'}}/>

      <div style={{padding: '15px', display: 'flex', flexDirection: 'column'}}>
        <Typography variant='h6'>{TEXT.eventDesc}</Typography>
        <Typography variant='body2'>{data.description}</Typography>
        <Typography variant='h6' style={{marginTop: '20px'}}>{TEXT.eventKnow}</Typography>
        <Typography variant='body2'>{data.remark}</Typography>
      </div>

      <div style={{padding: '15px', display: 'flex', flexDirection: 'column'}}>
        <Typography variant='h6'>{data.topic?data.topic.title:<></>}</Typography>
        <CommentBox variant='outline' style={{padding: '5px 10px', display: 'flex', flexDirection: 'column'}}>
          <div style={{width: '100%', display: 'flex'}}>
            <div style={{width: '9px'}}>
              <img src="/images/ic_profile_on.png" style={{width: '100%'}}/>
            </div>
            <Typography variant='body1' style={{marginLeft: '3px', fontSize: '14px'}}>
              {data.comment?
                data.comment.creatorName?data.comment.creatorName:data.comment.createdBy
              :<></>}
            </Typography>
            <Typography variant='body2' style={{marginLeft: 'auto', fontSize: '12px'}}>{data.comment?data.comment.createdDate:<></>}</Typography>
          </div>
          <div style={{width: '100%', marginTop: '10px', display: 'flex'}}>
            <Typography variant='body1' style={{fontSize: '14px'}}>{data.comment?data.comment.comment: <></>}</Typography>
          </div>
          <LikeBox>
          <div style={{width: '18px'}}>
            <img src='/images/ic_like_on.png' style={{width: '100%'}}/>
          </div>
          <Typography variant='body1' style={{marginLeft: '5px', fontSize: '14px'}}>{data.comment?data.comment.likeCount: <></>}</Typography>
          </LikeBox>
        </CommentBox>
      </div>
    </div>
  )
}

export default Content;