import { createContext, useState, useContext } from 'react';

export const EventDetailContext = createContext({
  data: {},
  setData: () => {},
});

export const EventDetailProvider = (props) => {
  const { children } = props;
  const [data, setData] = useState({});  

  return (
    <EventDetailContext.Provider
      value={{
        data, setData
      }}
    >
      {children}
    </EventDetailContext.Provider>
  );
};


export const EventDetailConsumer = EventDetailContext.Consumer;
export const useEventDetail = () => useContext(EventDetailContext);