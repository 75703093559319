import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { FixedFooter, NextBtn } from '../../common.style';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";

const TEXT = {
  resetPassword: '設定新密碼',
  password: '密碼',
  confirmPassword: '確認密碼',
  nextStep: '確定'
}

function Content(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: undefined,
      password: "",
      comfirmPassword: "",
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      password: Yup.string().required().label(TEXT.password),
      confirmPassword: Yup.string().required()
        .label(TEXT.confirmPassword)
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
    }),
    onSubmit: async (values, helpers) => {
      await handleSubmit(values, helpers);
    },
  });

  useEffect(() => {
    
  }, []);

  const handleSubmit = (values, helpers) => {
    setLoading(true);
    
    setLoading(false);
    localStorage.setItem('resetPasswordSuccess', true);
    navigate('/login');
  }

  return (
    <>
    <div style={{padding: '20px'}}>
      <Typography variant='body1' style={{fontSize: '18px', marginBottom: '10px'}}>{TEXT.resetPassword}</Typography>
      <TextField
        variant="outlined"
        fullWidth
        label={TEXT.password}
        name='password'
        type='password'

        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.password}
        helperText={
          Boolean(formik.touched.password && formik.errors.password) &&
          formik.errors.password
        }
        error={Boolean(formik.touched.password && formik.errors.password)}
        />

      <TextField
        variant="outlined"
        style={{marginTop: '10px'}}
        fullWidth
        label={TEXT.confirmPassword}
        name='confirmPassword'

        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.confirmPassword}
        helperText={
          Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword) &&
          formik.errors.confirmPassword
        }
        error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
        />
    </div>
    <div style={{marginTop: '9vh'}}></div>
    <FixedFooter style={{height: '10vh'}}>
        <NextBtn onClick={() => formik.handleSubmit()} disabled={loading}>{TEXT.nextStep}</NextBtn>
    </FixedFooter>
    </>
  )
}

export default Content;