import React from 'react';
import { Route, Routes } from "react-router-dom";
import ListEvent from './list/index';
import EventDetail from './detail/index';
import CheckInEvent from './checkin/index';
import Attendance from './attendance/index';



export const EventConfig = () => {
  return (
    <Routes>
      <Route path="/list" element={<ListEvent />}/>
      <Route path="/detail/:id" element={<EventDetail />}/>
      <Route path="/checkin" element={<CheckInEvent />}/>
      <Route path="/attendance/:id" element={<Attendance />}/>
    </Routes>
  )
}
export default EventConfig;