import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// import logo from '../../images/img_logo_black.png';

const Logo = styled('img')(({ theme }) => ({
   [theme.breakpoints.down('sm')]: {
      maxWidth: '60px',
   },
}));
function Header(){
 return(
   <Grid container spacing={2} style={{marginBottom: '50px'}}>
      <Grid item xs={10} >
        <Logo src='/images/img_logo_white.png'/>
      </Grid>
      <Grid item xs={2}>
        {/* <Button size='medium' style={{fontSize: '18px', color: '#000'}}>EN</Button> */}
      </Grid>
   </Grid>
 )   
}

export default Header;