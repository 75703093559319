import { createContext, useState, useContext } from 'react';
import { Outlet } from "react-router-dom";

export const AppContext = createContext({
  title: '',
  setTitle: () => {},
  showBackBtn: false,
  setShowBackBtn: () => {},
  showLogoutBtn: false,
  setShowLogoutBtn: () => {},
  html5QrCode: null, 
  setHtml5QrCode: () => {},
});

export const AppProvider = (props) => {
  const { children } = props;
  const [title, setTitle] = useState('');
  const [showBackBtn, setShowBackBtn] = useState(false);
  const [showLogoutBtn, setShowLogoutBtn] = useState(true);
  const [html5QrCode, setHtml5QrCode] = useState(null);

  return (
    <AppContext.Provider
      value={{
        title, setTitle, 
        showBackBtn, setShowBackBtn, 
        showLogoutBtn, setShowLogoutBtn,
        html5QrCode, setHtml5QrCode,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const AppConsumer = AppContext.Consumer;
export const useApp = () => useContext(AppContext);