import { useEffect } from 'react';
import { AttendanceProvider } from "./components/Attendance.context";
import Content from "./components/Content"
import { useApp } from '../../../App.context';

const TEXT = {
  title: '出席人數',
}

function Attendance(){
  const { setTitle, setShowBackBtn, setShowLogoutBtn } = useApp();

  useEffect(() => {
    // Content.js will update the title again
    setTitle(`${TEXT.title}(0)`);
    setShowBackBtn(true);
    setShowLogoutBtn(false);
  }, []);
  return (
    <>
    <AttendanceProvider>
      <Content/>
    </AttendanceProvider>
    </>
  )
}

export default Attendance;