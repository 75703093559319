import React from 'react';
import Login from '../pages/login/Login';
import EventConfig from '../pages/event/index';
import ForgetPasswordConfig from '../pages/forget_password';
import Layout from '../common/Layout';
import { AuthProvider } from '../auth/Auth.context';
import {
    Route,
    Routes,
    BrowserRouter,
    Navigate,
  } from "react-router-dom";

export const RouterConfig = () => {
 return (
    <Routes>
      <Route element={<AuthProvider />} >
        <Route path="*" element={<Navigate replace to="/event/list" />} />
        <Route path="/login" element={<Login />} />
        <Route element={<Layout />} >
          <Route path="/event/*" element={<EventConfig />} />
          <Route path="/forget_password/*" element={<ForgetPasswordConfig />} />
        </Route>
      </Route>
    </Routes>
 )   
}