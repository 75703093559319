const getAccessToken = () => {
  return window.localStorage.getItem('hkjc_access_token');
};

const getRefreshToken = () => {
  return window.localStorage.getItem('hkjc_refresh_token');
};

const setAccessToken = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('hkjc_access_token', accessToken);
  } else {
    localStorage.removeItem('hkjc_access_token');
  }
};

const setRefreshToken = (refreshToken) => {
  if (refreshToken) {
    localStorage.setItem('hkjc_refresh_token', refreshToken);
  } else {
    localStorage.removeItem('hkjc_refresh_token');
  }
};

const clearToken = (refreshToken) => {
  localStorage.removeItem('hkjc_access_token');
  localStorage.removeItem('hkjc_refresh_token');
};

export { getAccessToken, setAccessToken, getRefreshToken, setRefreshToken, clearToken };
