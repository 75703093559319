import { useEffect, useState, useRef, createRef } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useEventDetail } from './EventDetail.context';
import { useParams } from "react-router-dom";

const TEXT = {
  attendance: '出席人數',
  checkin: '簽到'
}

const AttendanceBtn = styled(Button)(({ theme }) => ({
    border: '1px solid white',
    color: 'white',
    padding: '10px', 
    margin: '0px 15px',
    width: '40vw',
    '&:hover': {
      border: '1px solid white',
    }
}));

const CheckInBtn = styled(Button)(({ theme }) => ({
  backgroundColor: 'white', 
  color: 'black', 
  padding: '10px', 
  margin: '0px 15px',
  width: '40vw',
  '&:hover': {
    backgroundColor: 'white',
  }
}));

export const FixedFooter = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,

  width: '100%',
  height: '10vh',

  backgroundColor: '#1D1D1D',

  display: 'flex', 
  justifyContent: 'center',
  alignItems: 'center',

}));

function Footer(props) {
  const navigate = useNavigate();
  const { data } = useEventDetail();
  let { id } = useParams();

  useEffect(() => {
  }, []);

  const handleAttendanceBtnClick = () => {
    navigate(`/event/attendance/${id}`);
  }

  const handleCheckInBtnClick = () => {
    navigate('/event/checkin');
  }

  return (
    <>
    <div style={{marginTop: '9vh'}}></div>
    <FixedFooter>
      <div style={{marginBottom: '10px', display: 'flex', justifyContent: 'center'}}>
        <AttendanceBtn onClick={() => handleAttendanceBtnClick()}>{TEXT.attendance}({data.statistic?data.statistic.attendedCount:<></>})</AttendanceBtn>
        <CheckInBtn onClick={() => handleCheckInBtnClick()}>{TEXT.checkin}</CheckInBtn>
      </div>
    </FixedFooter>
    </>
  )
}

export default Footer;