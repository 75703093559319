import axios from "axios";
import { getRefreshToken } from "../auth/TokenStorage";
import * as ApiPaths from "./constants";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 30000, // 30 sec
});

export default async function refreshToken() {
  const token = getRefreshToken();

  const response = await instance.get(ApiPaths.REFRESH_LOGIN, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
}
