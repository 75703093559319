import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useApp } from '../App.context';
import { useAuth } from '../auth/Auth.context'
import { useNavigate } from 'react-router-dom';


const Logo = styled('img')(({ theme }) => ({
   [theme.breakpoints.down('md')]: {
      maxWidth: '35px',
   },
}));

function Header(){
  const { title, showBackBtn, showLogoutBtn, html5QrCode, setHtml5QrCode } = useApp();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLBack = () => {
    if(html5QrCode != null){
      html5QrCode.stop().then(res =>{
        html5QrCode.clear();
        setHtml5QrCode(null);   
      }).catch(err => {

      });
    }
    navigate(-1);
  };

  const handleLogout = () => {
    logout();
    // navigate("/login");
  };

  return(
    <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static">
      <Toolbar style={{backgroundColor: '#1D1D1D'}}>
        {!showBackBtn?(
          <Logo src='/images/img_logo_white.png'/>
        ):(
          <>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              onClick={() => handleLBack()}
            >
              <ArrowBackIosIcon />
            </IconButton>
          </>
        )
        }

        <Typography variant="title1" component="div" sx={{ flexGrow: 1 }} style={{textAlign: 'center'}}>
          {title}
        </Typography>
        
        {showLogoutBtn?(
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            onClick={() => handleLogout()}
          >
            <LogoutIcon />
          </IconButton>):(<div style={{width: '24px'}}></div>)}
      </Toolbar>
    </AppBar>
    </Box>
  )   
}

export default Header;