import { useState, useEffect } from "react";
import { H } from "highlight.run";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Backdrop,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useAuth } from "../../../auth/Auth.context";

const TEXT = {
  phone: "電話",
  password: "密碼",
  forgetPassword: "忘記密碼",
  login: "登入",
};

const WhiteLink = styled(Link)(({ theme }) => ({
  color: "white",
  textDecoration: "none",
}));

function LoginForm() {
  const navigate = useNavigate();
  const { login } = useAuth();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetPasswordSuccessMessage, setResetPasswordSuccessMessage] =
    useState(false);

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      phone: Yup.string()
        .required()
        .matches(/^\d{8}$/, "Please enter Hong Kong phone number.")
        .label(TEXT.phone),
      password: Yup.string().required().label(TEXT.password),
    }),
    onSubmit: async (values, helpers) => {
      await handleLogin(values, helpers);
    },
  });

  const handleLogin = (values, helpers) => {
    setLoading(true);

    login(values)
      .then((res) => {
        H.identify(values["phone"]);
        setLoading(false);
        navigate("/event/list");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      localStorage.getItem("resetPasswordSuccess") &&
      localStorage.getItem("resetPasswordSuccess") === "true"
    ) {
      localStorage.setItem("resetPasswordSuccess", false);
      setResetPasswordSuccessMessage(true);
    }
  }, []);
  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: "50px" }}>
        <Grid item xs={12}>
          <div style={{ color: "white" }}>{process.env.API_URL}</div>
        </Grid>
        {resetPasswordSuccessMessage ? (
          <Grid item xs={12}>
            <Alert
              variant="filled"
              severity="success"
              onClose={() => setResetPasswordSuccessMessage(false)}
            >
              <AlertTitle>Success</AlertTitle>
              Your password has reset successfully.
            </Alert>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            label={TEXT.phone}
            name="phone"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.phone}
            helperText={
              Boolean(formik.touched.phone && formik.errors.phone) &&
              formik.errors.phone
            }
            error={Boolean(formik.touched.phone && formik.errors.phone)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            label={TEXT.password}
            name="password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((show) => !show)}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff style={{ color: "#fff" }} />
                    ) : (
                      <Visibility style={{ color: "#fff" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password}
            helperText={
              Boolean(formik.touched.password && formik.errors.password) &&
              formik.errors.password
            }
            error={Boolean(formik.touched.password && formik.errors.password)}
          />
        </Grid>
        <Grid
          container
          style={{ marginTop: 20, width: "100%" }}
          justifyContent="center"
          alignItems="center"
        >
          <WhiteLink to={"/forget_password/submit_email"}>
            {TEXT.forgetPassword}
          </WhiteLink>
        </Grid>

        <Grid
          container
          style={{ marginTop: 100, width: "100%" }}
          justifyContent="center"
          alignItems="center"
        >
          <div style={{ position: "relative" }}>
            <Button variant="text" onClick={() => formik.handleSubmit()}>
              <p
                style={{
                  position: "absolute",
                  top: "24%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "black",
                  fontSize: "16px",
                }}
              >
                {TEXT.login}
              </p>
              <img
                src="images/ic_register_button.png"
                style={{ maxWidth: "25vw" }}
              />
            </Button>
          </div>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default LoginForm;
