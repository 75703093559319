import * as ApiPaths from './constants';
import instance from './instance';

export async function login(action) {
  const data = {
    phone: action.data.phone,
    password: action.data.password
  };

  const response = await instance.post(ApiPaths.LOGIN, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: '',
    },
    skipAuthRefresh: true,
  });
  return response;
}
export async function getMe() {
  return instance.get(ApiPaths.GET_ME);
}

export async function getEventPage(action) {
  return instance.get(ApiPaths.LIST_EVENT, action.params );
}

export async function getEventPhoto(action) {
  return instance.get(ApiPaths.GET_EVENT_PHOTO(action.id), {}, {
    responseType: 'blob',
  });
}

export async function getEvent(action) {
  return instance.get(ApiPaths.GET_EVENT(action.id));
}

export async function checkinEvent(action) {
  return instance.post(ApiPaths.CHECK_IN_EVENT(action.id));
}

export async function getAttendantList(action) {
  return instance.get(ApiPaths.ATTENDANT_LIST_EVENT(action.id), action.params);
}