import moment from 'moment';
import 'moment/locale/zh-hk';

export const convertApiDateTimeToDisplayDateTime = (datetime) => {
  if (!datetime) {
    return '';
  }
  moment().locale('zh-hk');  
  return moment(datetime).format('lll').substring(5);
};

// export const convertApiDateTimeToDisplayDate = (datetime) => {
//   if (!datetime) {
//     return '';
//   }
//   return format(parseISO(datetime), 'yyyy.MM.dd');
// };

// export const convertApiDateTimeToDate = (datetime) => {
//   if (!datetime) {
//     return '';
//   }
//   return parseISO(datetime);
// };

// export const combineDateAndTimeToDateTime = (date, time) => {
//   return format(
//     set(date, {
//       hours: getHours(time),
//       minutes: getMinutes(time),
//       seconds: 0,
//       milliseconds: 0,
//     }),
//     "yyyy-MM-dd'T'HH:mm:ss"
//   );
// };

// export const extractDateFromDateTime = (datetime) => {
//   return moment(datetime).format('yyyy-MM-DD');
// };
// export const extractTimeFromDateTime = (datetime, second = "00") => {
//   return moment(datetime).second(second).format('HH:mm:ss');
// };
// export const convertDateObjectToDateTime = (date, time = "00:00:00") => {
//   return moment(date).format('yyyy-MM-DD') + "T" + time;
// };

// export const splitTime = (datetime) => {
//   const day = moment(datetime);
//   return {
//     hour: day.format("HH"),
//     minute: day.format("mm"),
//     second: day.format("ss"),
//     nano: "00",
//   };
// };

// export const getDateTimeFromTime = (time) => {
//   return moment().format('yyyy-MM-DD') + "T" + time;
// };