import { useEffect } from 'react';
import { ListEventProvider } from "./components/ListEvent.context";
import Content from "./components/Content"
import { useApp } from '../../../App.context';

const TEXT = {
  title: '活動',
}

function ListEvent(){
  const { setTitle, setShowBackBtn, setShowLogoutBtn } = useApp();

  useEffect(() => {
    setTitle(TEXT.title);
    setShowBackBtn(false);
    setShowLogoutBtn(true);
  }, []);
  return (
    <>
    <ListEventProvider>
      <Content/>
    </ListEventProvider>
    </>
  )
}

export default ListEvent;