import './App.css';
import { AppProvider } from './App.context';
import { RouterConfig } from "./router/RouterConfig";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter } from "react-router-dom";

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main : '#FFFFFF',
    },
    divider: {
      main : '#FFFFFF',
    },
    text: {
      main : '#FFFFFF',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            color: 'white'
          },
          '& label': {
            color: 'white',
          },
          '& label.Mui-focused': {
            color: 'white',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          }
        }
      }
    },
  },
  typography: {
    h4:{
      color: "white"
    },
    h5:{
      color: "white"
    },
    h6:{
      color: "white"
    },
    h7:{
      color: "white"
    },
    body1: {
      color: 'white'
    },
    body2: {
      color: '#8B8B8B',
      lineHeight: 1.8,
    }
  }
});

function App() {
  return (
    <div style={{minHeight: "100vh", height: '100vh', backgroundColor: "#000000", overflow: 'hidden'}}>
    <ThemeProvider theme={theme}>
      <AppProvider>
        {/* <AuthProvider> */}
          <BrowserRouter>
            <RouterConfig/>
          </BrowserRouter>
        {/* </AuthProvider> */}
      </AppProvider>
    </ThemeProvider>
    </div>
  );
}

export default App;
