import { useEffect } from 'react';
import Content from "./components/Content"
import { useApp } from '../../../App.context';

const TEXT = {
  title: '忘記密碼',
}

function SubmitEmail(){
  const { setTitle, setShowBackBtn, setShowLogoutBtn } = useApp();

  useEffect(() => {
    setTitle(`${TEXT.title}`);
    setShowBackBtn(false);
    setShowLogoutBtn(false);
  }, []);
  return (
    <>
      <Content/>
    </>
  )
}

export default SubmitEmail;