import { useEffect } from 'react';
import { EventDetailProvider } from "./components/EventDetail.context";
import Content from "./components/Content"
import { useApp } from '../../../App.context';
import Footer from './components/Footer';

function EventDetail(){
  const { setTitle, setShowBackBtn, setShowLogoutBtn } = useApp();

  useEffect(() => {
    setTitle("");
    setShowBackBtn(true);
    setShowLogoutBtn(false);
  }, []);

  return (
    <>
    <EventDetailProvider>
      <Content/>
      <Footer/>
    </EventDetailProvider>
    </>
  )
}

export default EventDetail;