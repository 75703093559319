import React from 'react';
import { Route, Routes } from "react-router-dom";
import SubmitEmail from './submit_email/index';
import ValidateEmail from './validate_email/index';
import ResetPassword from './reset_password/index';


export const ForgetPasswordConfig = () => {
  return (
    <Routes>
      <Route path="/submit_email" element={<SubmitEmail />}/>
      <Route path="/validate_email" element={<ValidateEmail />}/>
      <Route path="/reset_password" element={<ResetPassword />}/>
    </Routes>
  )
}
export default ForgetPasswordConfig;