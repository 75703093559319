import { useEffect, useState, useRef, createRef } from 'react';
import TextField from '@mui/material/TextField';
import { FixedFooter, NextBtn } from '../../common.style';
import { Typography } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";

const TEXT = {
  validateEmail: '驗證碼已發送到你的註冊電郵',
  pleaseEnter: '請輸入',
  nextStep: '下一步',
}

function Content(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const inputNumRef = useRef([...Array(6)].map((_, i) => createRef()));

  const getInitNums = () => {
    return [...Array(6)].map((_,v) => "");
  }

  const formik = useFormik({
    initialValues: {
      id: undefined,
      nums: getInitNums(),
    },
    validateOnMount: true,
    validationSchema: Yup.object({
    }),
    onSubmit: async (values, helpers) => {
      await handleSubmit(values, helpers);
    },
  });
  
  useEffect(() => {
    inputNumRef.current[0].current.focus();
  }, []);
  
  const handleNumChange = (index, e) => {
    if(e.target.value.length > 1){
      return;
    }

    const prefix = "nums";
    formik.setFieldValue(`${prefix}[${index}]`, e.target.value);
    
    if(index < 5 && e.target.value.length === 1){
      inputNumRef.current[index+1].current.focus();
    }

  }

  const handleSubmit = (values, helpers) => {
    setLoading(true);
    
    setLoading(false);

    console.log(values);
    navigate('/forget_password/reset_password');
    // navigate('/login');
  }

  return (
    <>
    <div style={{padding: '20px'}}>
      <Typography variant='body1' style={{fontSize: '18px', marginBottom: '10px'}}>{TEXT.validateEmail}</Typography>
      <Typography variant='body1' style={{fontSize: '16px', marginBottom: '15px'}}>{TEXT.pleaseEnter}</Typography>
      <div style={{display: 'flex'}}>
        {formik.values.nums.map((option, i) => {
          const fieldValues = formik.values.nums[i];
          return (
            <TextField
              variant="outlined"
              name={`nums[${i}]`}
              inputRef={inputNumRef.current[i]} 
              style={i===0?{margin:"0px 0px 0px 0px"}:{margin:"0px 0px 0px 5px"}}
              onBlur={formik.handleBlur}
              onChange={(e) => handleNumChange(i, e)}
              value={fieldValues}
            />  
          )
        })}
      </div>
    </div>
    <div style={{marginTop: '9vh'}}></div>
    <FixedFooter style={{height: '10vh'}}>
        <NextBtn onClick={() => formik.handleSubmit()} disabled={loading}>{TEXT.nextStep}</NextBtn>
    </FixedFooter>
    </>
  )
}
export default Content;