import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const NextBtn = styled(Button)(({ theme }) => ({
  backgroundColor: 'white', 
  color: 'black', 
  padding: '10px', 
  marginBottom: '20px',
  width: '90%',

  '&:hover': {
    backgroundColor: 'white',
  },
  '&:disabled': {
    backgroundColor: '#8B8B8B',
    color: 'white'
  }
}));
export const FixedFooter = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,

  width: '100%',
  height: '10vh',

  backgroundColor: '#000000',

  display: 'flex', 
  justifyContent: 'center',
  alignItems: 'center',

}));
