import { useEffect, useState, useRef, createRef } from 'react';
import { Typography } from '@mui/material';
import { useApp } from '../../../../App.context';
import { useParams } from "react-router-dom";
import { getAttendantList } from '../../../../api/apis';

const TEXT = {
  title: '出席人數',
}

function Content(props) {
  const { setTitle } = useApp();
  let { id } = useParams();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  
  useEffect(() => {
    const params = {
      page,
      size: 100
    }
    getAttendantList({ id, params }).then(res => {
      if(res.status == 200){
        setTitle(`${TEXT.title}(${res.data.totalElements})`);
        setData(res.data.content);
      } else {
        console.log('err');
        console.log(res);
      }
    })
    
  }, []);

  return (
    <div style={{padding: '20px', display:'flex', flexDirection: 'column', height: '80%', overflow: 'scroll'}}>
      {data.map(row => (
        <div key={row.id} style={{margin: '20px 0px 0px 2px', display: 'flex', alignItems: 'center'}}>
          <img src='/images/ic_profile_on.png' width={15}/>
          <Typography variant='body1' style={{marginLeft: '14px'}}>{(row.memberName)}</Typography>
        </div>
      ))}
    </div>
  )
}

export default Content;