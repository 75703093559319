import { useEffect, useState, useRef, createRef } from 'react';
import { Html5Qrcode } from "html5-qrcode"
import { useApp } from '../../../../App.context';
import { useCheckInEvent } from './CheckInEvent.context';
import { checkinEvent } from '../../../../api/apis';
const TEXT = {

}

function QRcodeScanner(props) {
  // const {qrcodeShown, setQrcodeShown} = useCheckInEvent();
  const { html5QrCode, setHtml5QrCode } = useApp();
  const { setIsCheckingPassShow, setPageJumpTrigger, setErrorMessage } = useCheckInEvent();
  const [ isWaitRequest, setIsWaitRequest ] = useState(false);
  
  const config = { fps: 12 };

  const handleSuccessCheckin = () => {
    setIsCheckingPassShow(true);

      setTimeout(() => {
        html5QrCode.stop().then(res =>{
          html5QrCode.clear();
          setHtml5QrCode(null);
          setPageJumpTrigger(true);
        }).catch(err => {

        })
      }, 3000);
  }
  useEffect(() => {
    setHtml5QrCode(new Html5Qrcode("reader"))
  }, []);

  useEffect(() => {
    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      /* handle success */
      html5QrCode.pause();
      console.log(`Code matched = ${decodedText}`, decodedResult);
      
      checkinEvent({ id:decodedText }).then(res => {
        if(res.status == 200){
          setErrorMessage(null);
          handleSuccessCheckin();
        } else {
          setErrorMessage(`${res.data.message}: ${res.data.detail}`);
          setTimeout(() => {
            html5QrCode.resume();
          }, 1000);  
        }
      }).catch(err => {
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          html5QrCode.resume();
        }, 1000);
      })
      
    };

    Html5Qrcode.getCameras().then(devices => {
      /**
       * devices would be an array of objects of type:
       * { id: "id", label: "label" }
       */
      if (devices && devices.length) {
        var cameraId = devices[0].id;
        html5QrCode.start({ facingMode: "environment" }, config, qrCodeSuccessCallback);
        // .. use this to start scanning.
      }
    }).catch(err => {
      // handle err
      console.log(err);
    });
  }, [html5QrCode])
  return (
    <>
    {/* <input type="file" id="qr-input-file" accept="image/*" onChange={(e) => onFileInput(e)}/> */}
    <div id="reader" style={{width: '100%'}}>
    </div>
    </>
  )
}

export default QRcodeScanner;