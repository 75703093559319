import { createContext, useState, useContext } from 'react';

export const ListEventContext = createContext({
    
});

export const ListEventProvider = (props) => {
  const { children } = props;
  

  return (
    <ListEventContext.Provider
      value={{
        
      }}
    >
      {children}
    </ListEventContext.Provider>
  );
};


export const ListEventConsumer = ListEventContext.Consumer;
export const useListEvent = () => useContext(ListEventContext);