import { useEffect, useState, useRef, createRef } from 'react';
import { Typography } from '@mui/material';
import QRcodeScanner from './QRcodeScanner';
import { useCheckInEvent } from './CheckInEvent.context';
import { useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const TEXT = {
  scannQRcode: '掃描QR Code簽到',
}

function Content(props) {
  const navigate = useNavigate();
  const [data, setData] = useState("No result");
  const { isCheckingPassShow, pageJumpTrigger, errorMessage, setErrorMessage } = useCheckInEvent();

  useEffect(() => {
    if(pageJumpTrigger){
      navigate(-1);
    }
  }, [pageJumpTrigger]);

  return (
    <div style={{padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      {errorMessage !== null?
        <Alert variant="filled" severity="error" onClose={() => setErrorMessage(null)} style={{width: '80%'}}>
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>:<></>}
      <div style={{width: '70vw', minHeight: '20vh', position: 'relative', marginTop: '7vh'}}>
        <QRcodeScanner/>
        <img src='/images/ic_scan.png' style={{width: '50vw', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 999}}/>
        {isCheckingPassShow? 
          <img src='/images/ic_checkin_pass.png' style={{width: '40vw', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 999}}/>
          :<></>
        }
        
      </div>
      <Typography variant='h6' style={{marginTop: '50px'}}>{TEXT.scannQRcode}</Typography>
    </div>
  )
}

export default Content;