import { useEffect } from 'react';
import Content from "./components/Content"
import { useApp } from '../../../App.context';

const TEXT = {
  title: '重設密碼',
}

function ResetPassword(){
  const { setTitle, setShowBackBtn, setShowLogoutBtn } = useApp();

  useEffect(() => {
    setTitle(`${TEXT.title}`);
    setShowBackBtn(true);
    setShowLogoutBtn(false);
  }, []);
  return (
    <>
      <Content/>
    </>
  )
}

export default ResetPassword;