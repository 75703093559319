import { createContext, useState, useContext } from 'react';

export const AttendanceContext = createContext({
  qrcodeShown: false,
  setQrcodeShown: () => {},
});

export const AttendanceProvider = (props) => {
  const [qrcodeShown, setQrcodeShown] = useState(false);
  const { children } = props;
  

  return (
    <AttendanceContext.Provider
      value={{
        qrcodeShown,
        setQrcodeShown
      }}
    >
      {children}
    </AttendanceContext.Provider>
  );
};


export const AttendanceConsumer = AttendanceContext.Consumer;
export const useAttendance = () => useContext(AttendanceContext);