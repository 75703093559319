import { useEffect } from 'react';
import { CheckInEventProvider } from "./components/CheckInEvent.context";
import Content from "./components/Content"
import { useApp } from '../../../App.context';
import QRcodeScanner from './components/QRcodeScanner';

const TEXT = {
  title: '簽到',
}

function CheckInEvent(){
  const { setTitle, setShowBackBtn, setShowLogoutBtn } = useApp();

  useEffect(() => {
    setTitle(TEXT.title);
    setShowBackBtn(true);
    setShowLogoutBtn(false);
  }, []);
  return (
    <>
    <CheckInEventProvider>
      <Content/>
    </CheckInEventProvider>
    </>
  )
}

export default CheckInEvent;