import React from 'react';
import Header from "./components/Header"
import LoginForm from "./components/LoginForm"

function Login(){
  return (
    <div style={{padding: '20px'}}>
      <Header/>
      <LoginForm/>
    </div>
  )
}

export default Login;